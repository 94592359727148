<template>
  <Container class="testimonial-wrap">
    <div class="testimonial_section">
      <AlchemyTestimonial
        v-for="(quote, index) in testimonials"
        :key="quote.id"
        :element="quote"
        :color="COLORS[index % COLORS.length]"
      />
    </div>
  </Container>
</template>

<script>
  import AlchemyTestimonial from "./alchemy_testimonial"
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import Container from "~/components/Container"

  const COLORS = ["red", "blue", "purple"]

  export default {
    components: { AlchemyTestimonial, Container },
    mixins: [AlchemyElement],
    data() {
      return {
        COLORS,
      }
    },
    computed: {
      testimonials() {
        return this.element?.nestedElements
      },
    },
  }
</script>

<style lang="scss" scoped>
  .testimonial-wrap {
    margin-bottom: $space-m;
  }
  .testimonial_section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: $base-spacing * 8;
    @include viewport("mini", "sm") {
      grid-template-columns: 1fr;
    }
  }
</style>
